// import '../setup'
import { AppProps, AppType } from 'next/app'
import Head from 'next/head'

import './globals.css'
import { usePlace } from 'store/usePlace'

const App: AppType = ({ Component, pageProps }: AppProps) => {
  const place = usePlace(p => p.place)
  const title = place?.name || '2meters'

  const favicon = place?.customisation?.favIcon || '/icons/favicon.ico'
  const appliIcon = place?.customisation?.favIcon || '/icons/2meters-logo-180x180.png'

  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <link rel='icon' href={favicon} />
        <link rel='apple-touch-icon' href={appliIcon} />
        <title>{title}</title>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.REACT_APP_GTM_KEY}'); 
          `,
          }}
        />
      </Head>
      <main>
        <Component {...pageProps} />
      </main>
    </>
  )
}

export default App
